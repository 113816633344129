'use client'

import { getCookie, setCookie } from 'cookies-next'
import { useContext, useEffect, useRef, useState } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import ModalNewsletter from '../Modal/ModalNewsletter'
import { usePathname } from 'next/navigation'
import { UserContext, UserContextType } from '../../context/UserContext'

const COOKIE_NEWSLETTER_CLOSED_TIMES_COUNT = 'newsletter:closed-times-count'
export const COOKIE_NEWSLETTER_USER_ALREADY_SUBSCRIBED = 'newsletter:user-already-subscribed'

const COOKIE_NEWSLETTER_FIRST_PAGE_URI = 'newsletter:first-page-uri'
const COOKIE_NEWSLETTER_SECOND_PAGE_URI = 'newsletter:second-page-uri'
const COOKIE_NEWSLETTER_THIRD_PAGE_URI = 'newsletter:third-page-uri'

const FIRST_PAGE_INACTIVE_TIMEOUT_DELAY = 30000
const SECOND_PAGE_INACTIVE_TIMEOUT_DELAY = 5000
const THIRD_PAGE_INACTIVE_TIMEOUT_DELAY = 500

const COOKIE_NEWSLETTER_CLOSED_PAGES = 'newsletter:closed-pages'

const MAX_DISPLAY_COUNT_IN_A_WEEK = 3

export const CookieNewsletter = () => {
  const currentPath = usePathname()

  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType
  const { user } = useContext(UserContext) as UserContextType

  const [modalUuid, setModalUuid] = useState<string>()

  const modalIsDisplayedRef = useRef(false)
  const endOfPageReachedRef = useRef(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const inactiveDelayRef = useRef<number>(0)

  const removeLocaleFromPath = (path: string | null): string => {
    if (!path) return ''
    return path.replace(/^\/[a-z]{2}(_[a-zA-Z]{2})?(\/|$)/, '/')
  }

  // Cookie helpers
  const getClosedTimes = (): number =>
    parseInt((getCookie(COOKIE_NEWSLETTER_CLOSED_TIMES_COUNT) as string) || '0')
  const getUserAlreadySubscribed = (): boolean =>
    !!getCookie(COOKIE_NEWSLETTER_USER_ALREADY_SUBSCRIBED)
  const incrementClosedTimes = (): void =>
    setCookie(COOKIE_NEWSLETTER_CLOSED_TIMES_COUNT, getClosedTimes() + 1)

  const displayNewsletterModal = (): void => {
    if (currentPath?.includes('/newsletter')) {
      return
    }

    if (
      !getUserAlreadySubscribed() &&
      getClosedTimes() < MAX_DISPLAY_COUNT_IN_A_WEEK &&
      !modalIsDisplayedRef.current
    ) {
      const uuid = crypto.randomUUID()
      setModalUuid(uuid)
      addModal({
        uuid,
        component: <ModalNewsletter uuid={uuid} onClose={handleClose} />,
      })
      modalIsDisplayedRef.current = true
    }
  }

  // Helpers to get/set first, second and third page URIs in cookies
  const getOrDefineFirstPageUri = (): string => {
    let cookie = getCookie(COOKIE_NEWSLETTER_FIRST_PAGE_URI) as string
    if (!cookie && currentPath) {
      setCookie(COOKIE_NEWSLETTER_FIRST_PAGE_URI, encodeURI(removeLocaleFromPath(currentPath)))
      cookie = removeLocaleFromPath(currentPath)
    }
    return decodeURI(cookie)
  }

  const getOrDefineSecondPageUri = (): string | undefined => {
    const firstPageUri = getOrDefineFirstPageUri()
    let cookie = getCookie(COOKIE_NEWSLETTER_SECOND_PAGE_URI) as string
    if (!cookie && currentPath && firstPageUri !== removeLocaleFromPath(currentPath)) {
      setCookie(COOKIE_NEWSLETTER_SECOND_PAGE_URI, encodeURI(removeLocaleFromPath(currentPath)))
      cookie = removeLocaleFromPath(currentPath)
      return decodeURI(cookie)
    }
    return decodeURI(cookie)
  }

  const getOrDefineThirdPageUri = (): string | undefined => {
    const firstPageUri = getOrDefineFirstPageUri()
    const secondPageUri = getOrDefineSecondPageUri()
    let cookie = getCookie(COOKIE_NEWSLETTER_THIRD_PAGE_URI) as string
    if (
      !cookie &&
      currentPath &&
      firstPageUri !== removeLocaleFromPath(currentPath) &&
      secondPageUri !== removeLocaleFromPath(currentPath)
    ) {
      setCookie(COOKIE_NEWSLETTER_THIRD_PAGE_URI, encodeURI(removeLocaleFromPath(currentPath)))
      cookie = removeLocaleFromPath(currentPath)
      return decodeURI(cookie)
    }
    return decodeURI(cookie)
  }

  const getClosedPages = (): string[] => {
    const cookie = getCookie(COOKIE_NEWSLETTER_CLOSED_PAGES) as string
    return cookie ? JSON.parse(cookie).map((uri: string) => decodeURI(uri)) : []
  }

  const addClosedPage = (pageURI: string): void => {
    const closedPages = getClosedPages()
    if (!closedPages.includes(pageURI)) {
      closedPages.push(pageURI)
      setCookie(COOKIE_NEWSLETTER_CLOSED_PAGES, JSON.stringify(closedPages))
    }
  }

  // Function to determine the inactive delay based on current conditions
  const getInactiveDelay = (): number => {
    const currentPageUri = removeLocaleFromPath(currentPath)
    const firstPageUri = getOrDefineFirstPageUri()
    const secondPageUri = getOrDefineSecondPageUri()
    const thirdPageUri = getOrDefineThirdPageUri()

    if (
      firstPageUri === removeLocaleFromPath(currentPath) &&
      !getClosedPages().includes(currentPageUri)
    ) {
      return FIRST_PAGE_INACTIVE_TIMEOUT_DELAY
    } else if (
      secondPageUri === removeLocaleFromPath(currentPath) &&
      endOfPageReachedRef.current &&
      !getClosedPages().includes(currentPageUri)
    ) {
      return SECOND_PAGE_INACTIVE_TIMEOUT_DELAY
    } else if (
      thirdPageUri === removeLocaleFromPath(currentPath) &&
      endOfPageReachedRef.current &&
      !getClosedPages().includes(currentPageUri)
    ) {
      return THIRD_PAGE_INACTIVE_TIMEOUT_DELAY
    } else {
      return 0
    }
  }

  const rules = () => {
    inactiveDelayRef.current = getInactiveDelay()
    setupTimerAndListeners()
  }

  // Set up timer and scroll listener based on inactiveDelay
  const setupTimerAndListeners = () => {
    // Clear previous timer
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }

    // Remove previous scroll listener
    window.removeEventListener('scroll', handleScroll)
    window.removeEventListener('mousemove', handleScroll)

    // Set up new timer and listener if needed
    if (inactiveDelayRef.current > 0 && !modalIsDisplayedRef.current) {
      timerRef.current = setTimeout(() => {
        displayNewsletterModal()
      }, inactiveDelayRef.current)

      window.addEventListener('scroll', handleScroll)
      window.addEventListener('mousemove', handleScroll)
    }
  }

  const handleScroll = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
    if (inactiveDelayRef.current > 0) {
      timerRef.current = setTimeout(() => {
        displayNewsletterModal()
      }, inactiveDelayRef.current)
    }
  }

  // Apply rules on mount and when currentPath changes
  useEffect(() => {
    rules()
    // Clean up on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('mousemove', handleScroll)
    }
  }, [currentPath])

  // Handle modal footer intersection
  useEffect(() => {
    const footerElement = document.querySelector('.footer')
    if (!footerElement) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        endOfPageReachedRef.current = entry.isIntersecting
        rules()
      },
      { root: null },
    )

    observer.observe(footerElement)

    return () => {
      if (footerElement) observer.unobserve(footerElement)
    }
  }, [currentPath])

  // Handle user already subscribed
  useEffect(() => {
    if (user && user.is_subscribed && !getUserAlreadySubscribed()) {
      setCookie(COOKIE_NEWSLETTER_USER_ALREADY_SUBSCRIBED, '1')
    }
  }, [currentPath])

  const handleClose = () => {
    addClosedPage(removeLocaleFromPath(currentPath))
    incrementClosedTimes()
    if (modalUuid) removeModal(modalUuid)
    setModalUuid(undefined)
    modalIsDisplayedRef.current = false
    rules()
  }

  return null
}
